@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');

html {
  font-family: 'Roboto', sans-serif;
}

.widget-wrap {
  position: relative;
  flex-wrap: wrap;
  padding: 10px;
}

.fixed-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Make sure it appears above other content */
}



/* top */
.shapedividers_com-8613{
  overflow:hidden;
  position:relative;
  }
  .shapedividers_com-8613::before{
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  top: -1px;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat; 
  background-size: 100% 71px;
  background-position: 50% 100%;    
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23ffffff"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23ffffff"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23ffffff"/></svg>'); 
  }
  
  @media (min-width:768px){
  .shapedividers_com-8613::before{
  background-size: 100% 71px;
  background-position: 50% 100%;   
  }  
  }
   
  @media (min-width:1025px){
  .shapedividers_com-8613::before{ 
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 100% 51px;
  background-position: 50% 0%;  
  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23fef3c7"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23fef3c7"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23fef3c7"/></svg>'); 
  }
  }
  @media (min-width:2100px){
  .shapedividers_com-8613::before{
  background-size: 100% calc(2vw + 51px);
  }
  }
   


  /* bottom  */

  .shapedividers_com-2511{
    overflow:hidden;
    position:relative;
    }
    .shapedividers_com-2511::before{
    content:'';
    font-family:'shape divider from ShapeDividers.com';
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat; 
    background-size: 100% 71px;
    background-position: 50% 100%;    
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23ffffff"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23ffffff"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23ffffff"/></svg>'); 
    }
    
    @media (min-width:768px){
    .shapedividers_com-2511::before{
    background-size: 100% 71px;
    background-position: 50% 100%;   
    }  
    }
     
    @media (min-width:1025px){
    .shapedividers_com-2511::before{ 
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw; 
    background-size: 100% 51px;
    background-position: 50% 100%;  
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23fef3c7"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23fef3c7"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23fef3c7"/></svg>'); 
    }
    }
    @media (min-width:2100px){
    .shapedividers_com-2511::before{
    background-size: 100% calc(2vw + 51px);
    }
    }
     


    .vidHeight {
      height: 80vh;
    }

    /* white bottom */

    .shapedividers_com-5938{
      overflow:hidden;
      position:relative;
      }
      .shapedividers_com-5938::before{
      content:'';
      font-family:'shape divider from ShapeDividers.com';
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      top: -1px;
      z-index: 3;
      pointer-events: none;
      background-repeat: no-repeat; 
      background-size: 100% 71px;
      background-position: 50% 100%;    
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23ffffff"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23ffffff"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23ffffff"/></svg>'); 
      }

      
.shapedividers_com-6777{
overflow:hidden;
position:relative;
}
.shapedividers_com-6777::before{ 
content:'';
font-family:'shape divider from ShapeDividers.com';
position: absolute;
z-index: 3;
pointer-events: none;
background-repeat: no-repeat;
bottom: -0.1vw;
left: -0.1vw;
right: -0.1vw;
top: -0.1vw; 
background-size: 100% 90px;
background-position: 50% 100%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23fef3c7"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23fef3c7"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23fef3c7"/></svg>'); 
}

@media (min-width:2100px){
.shapedividers_com-6777::before{
background-size: 100% calc(2vw + 90px);
}
}
 
      
      @media (min-width:768px){
      .shapedividers_com-5938::before{
      background-size: 100% 71px;
      background-position: 50% 100%;   
      }  
      }
       
      @media (min-width:1025px){
      .shapedividers_com-5938::before{ 
      bottom: -0.1vw;
      left: -0.1vw;
      right: -0.1vw;
      top: -0.1vw; 
      background-size: 100% 51px;
      background-position: 50% 100%;  
      }
      }
      @media (min-width:2100px){
      .shapedividers_com-5938::before{
      background-size: 100% calc(2vw + 51px);
      }
      }
       

      .shapedividers_com-8199{
        overflow:hidden;
        position:relative;
        }
        .shapedividers_com-8199::before{
        content:'';
        font-family:'shape divider from ShapeDividers.com';
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        top: -1px;
        z-index: 3;
        pointer-events: none;
        background-repeat: no-repeat; 
        background-size: 100% 90px;
        background-position: 50% 0%;    
        background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23fbd8c2"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23fbd8c2"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23fbd8c2"/></svg>'); 
        }
        
        @media (min-width:768px){
        .shapedividers_com-8199::before{
        background-size: 100% 90px;
        background-position: 50% 0%;   
        }  
        }
         
        @media (min-width:1025px){
        .shapedividers_com-8199::before{ 
        bottom: -0.1vw;
        left: -0.1vw;
        right: -0.1vw;
        top: -0.1vw; 
        background-size: 100% 90px;
        background-position: 50% 100%;  
        background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23000000"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23000000"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23000000"/></svg>'); 
        }
        }
        @media (min-width:2100px){
        .shapedividers_com-8199::before{
        background-size: 100% calc(2vw + 90px);
        }
        }

        
.shapedividers_com-9614{
  overflow:hidden;
  position:relative;
  }
  .shapedividers_com-9614::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 218% 348px;
  background-position: 25% 100%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 476.62 100.69"><path fill="%23fbd8c2" d="M476.62 100.69V0l-238.3 95.47L0 0v100.69Z"/></svg>'); 
  }
  
  @media (min-width:2100px){
  .shapedividers_com-9614::before{
  background-size: 218% calc(2vw + 348px);
  }
  }

  
.shapedividers_com-8792{
  overflow:hidden;
  position:relative;
  }
  .shapedividers_com-8792::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 218% 348px;
  background-position: 25% 100%;  background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 476.62 100.69"><path fill="%23332c22" d="M476.62 100.69V0l-238.3 95.47L0 0v100.69Z"/></svg>'); 
  }
  
  @media (min-width:2100px){
  .shapedividers_com-8792::before{
  background-size: 218% calc(2vw + 348px);
  }
  }

  
.shapedividers_com-8533{
  overflow:hidden;
  position:relative;
  }
  .shapedividers_com-8533::before{ 
  content:'';
  font-family:'shape divider from ShapeDividers.com';
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 218% 348px;
  background-position: 25% 0%; 
  transform: rotateY(180deg); background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 476.62 100.69"><path fill="%23332c22" d="M0 0v100.69L238.3 5.22l238.32 95.47V0Z"/></svg>'); 
  }
  
  @media (min-width:2100px){
  .shapedividers_com-8533::before{
  background-size: 218% calc(2vw + 348px);
  }
  }


  .shapedividers_com-5923{
    overflow:hidden;
    position:relative;
    }
    .shapedividers_com-5923::before{
    content:'';
    font-family:'shape divider from ShapeDividers.com';
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat; 
    background-size: 100% 90px;
    background-position: 50% 0%;    
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23fbd8c2"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23fbd8c2"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23fbd8c2"/></svg>'); 
    }
    
    @media (min-width:768px){
    .shapedividers_com-5923::before{
    background-size: 100% 90px;
    background-position: 50% 0%;   
    }  
    }
     
    @media (min-width:1025px){
    .shapedividers_com-5923::before{ 
    bottom: -0.1vw;
    left: -0.1vw;
    right: -0.1vw;
    top: -0.1vw; 
    background-size: 220% 103px;
    background-position: 32% 100%;  
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 476.62 100.69"><path fill="%23332c22" d="M476.62 100.69V0l-238.3 95.47L0 0v100.69Z"/></svg>'); 
    }
    }
    @media (min-width:2100px){
    .shapedividers_com-5923::before{
    background-size: 220% calc(2vw + 103px);
    }
    }
     
   
   